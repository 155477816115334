import React from "react";
import styles from "./ForthSection.module.scss";
import { ReactComponent as ChevronRight } from "../../../../assets/chevron-right.svg";

const ForthSection = ({mobileView}) => {
  return (
    <div className={styles.forthsectionWrapper}>
      <div className={styles.forthsection}>
        <div className={styles.forthsectioncard}>
          <div className={styles.number}>1</div>
          <div className={styles.hori}></div>
          <h3>Select your number</h3>
        </div>
        <ChevronRight />
        <div className={styles.forthsectioncard}>
          <div className={styles.number}>2</div>
          <div className={styles.hori}></div>
          <h3>Specify your operational hours</h3>
        </div>
        <ChevronRight />
        <div className={styles.forthsectioncard}>
          <div className={styles.number}>3</div>
          <div className={styles.hori}></div>
          <h3>Setup your voicemail</h3>
        </div>
        <ChevronRight />
        <div className={styles.forthsectioncard}>
          <div className={styles.number}>4</div>
          <div className={styles.hori}></div>
          <h3>Setup Complete </h3>
        </div>
      </div>
      <button>Setup your business phone system</button>
    </div>
  );
};

export default ForthSection;

import React from "react";
import styles from "./SecondSection.module.scss";

const SecondSection = ({mobileView}) => {
  return (
    <div className={styles.secondsection}>
      <div className={styles.yellowSection}>
        <h3>Very easy to use</h3>
        <p>
          The only phone system you need for your small business. No complex,
          unwanted features. Just the basics that you need to run your business.
        </p>
      </div>

      <div className={styles.cards}>
        <div className={styles.card}>
          <h3>Only the essential, no pointless features</h3>
          <p>
            Made specially for small businesses, our solution is design so you
            can focus on what matters : your work.
          </p>
        </div>
        <div className={styles.card}>
          <h3>Easy to use, no technical skills needed</h3>
          <p>
            Made specially for small businesses, our solution is design so you
            can focus on what matters : your work.
          </p>
        </div>
      </div>

      <div className={styles.secondsectionbottom}>
        <h3>Features</h3>
        <h5>Never miss a single customer call</h5>
        <p>
          We understand how important is your customer calls. Never miss a
          single call with our powerful yet simple features.
        </p>
      </div>
    </div>
  );
};

export default SecondSection;

import React from "react";
import styles from "./FifthSection.module.scss";
import { ReactComponent as Laptop } from "../../../../assets/laptop.svg";
import { ReactComponent as Rightpointingarrow } from "../../../../assets/rightpointingarrow.svg";
const FifthSection = ({mobileView}) => {
  return (
    <div className={styles.fifthsection}>
      <h3>Pricing</h3>
      <p> A simple, easy and straightforward pricing.</p>

      <div className={styles.pricingInfoWrapper}>
        <Laptop />
        <div className={styles.pricingInfo}>
          <div className={styles.price}>$12.99 USD /month</div>
          <button>
            Get started <Rightpointingarrow />{" "}
          </button>
          <p>*No hidden costs, no commitments.</p>
        </div>
      </div>
    </div>
  );
};

export default FifthSection;

import React, { useEffect, useState } from "react";
import styles from "./BottomSection.module.scss";
import AlloBlueLogo from "../../../../assets/AlloblueLogo.png";
import { ReactComponent as Youtube } from "../../../../assets/youtube.svg";
import { ReactComponent as Instagram } from "../../../../assets/instagram.svg";
import { ReactComponent as Twitter } from "../../../../assets/twitter.svg";
import { ReactComponent as Facebook } from "../../../../assets/facebook.svg";
import { ReactComponent as Laptop } from "../../../../assets/laptop.svg";
import ReviewSection from "./Reviews/Review";
const BottomSection = ({ mobileView }) => {
  return (
    <div className={styles.bottomsection}>
      <h3>About us </h3>
      <div className={styles.reviewsection}>
        {mobileView ? (
          <ReviewSection />
        ) : (
          <>
            <div className={styles.reviewCard}>
              <p>
                Lorem ipsum dolor sit amet. Ad minima iure quo nostrum iusto in
                architecto molestiae! Et voluptas molestiae et autem asperiores
                in itaque officia qui cumque galisum aut maiores provident aut
                voluptate labore qui sapiente exercitationem.
              </p>
              <h4>Atmadeep Das</h4>
              <h5>Founder & CEO - Acciolbis</h5>
            </div>
            <div className={styles.reviewCard}>
              <p>
                Lorem ipsum dolor sit amet. Ad minima iure quo nostrum iusto in
                architecto molestiae! Et voluptas molestiae et autem asperiores
                in itaque officia qui cumque galisum aut maiores provident aut
                voluptate labore qui sapiente exercitationem.
              </p>
              <h4>Atmadeep Das</h4>
              <h5>Founder & CEO - Acciolbis</h5>
            </div>
            <div className={styles.reviewCard}>
              <p>
                Lorem ipsum dolor sit amet. Ad minima iure quo nostrum iusto in
                architecto molestiae! Et voluptas molestiae et autem asperiores
                in itaque officia qui cumque galisum aut maiores provident aut
                voluptate labore qui sapiente exercitationem.
              </p>
              <h4>Atmadeep Das</h4>
              <h5>Founder & CEO - Acciolbis</h5>
            </div>
          </>
        )}
      </div>

      {!mobileView && (
        <div className={styles.bottommiddle}>
          <Laptop />
          <button>Get your business phone number</button>
        </div>
      )}

      <div className={styles.footer}>
        <img src={AlloBlueLogo} alt="" />
        <p>
          The only phone system you need for your small business. No complex,
          unwanted features. Just the basics that you need to run your business.
        </p>
        <div className={styles.cp}>
          Copyright AlloBlue © 2022 All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default BottomSection;

import React from "react";
import styles from "./ForthSection.module.scss";

const ForthSectionMobile = () => {
  return (
    <div className={styles.forthsectionmobile}>
      <div className={styles.forthcard}>
        <div className={styles.vertical}></div>
        <div className={styles.number}>1</div>
        <h4>Select your number</h4>{" "}
      </div>
      <div className={styles.forthcard}>
        <div className={styles.vertical}></div>
        <div className={styles.number}>2</div>
        <h4>Specify your operational hours</h4>{" "}
      </div>{" "}
      <div className={styles.forthcard}>
        <div className={styles.vertical}></div>
        <div className={styles.number}>3</div>
        <h4>Setup your voicemail</h4>{" "}
      </div>{" "}
      <div className={styles.forthcard}>
        <div className={styles.vertical}></div>
        <div className={styles.number}>4</div>
        <h4>Payment</h4>{" "}
      </div>
      <button>Setup your business phone system</button>
    </div>
  );
};

export default ForthSectionMobile;

import React, { useEffect, useState } from "react";
import styles from "./ThirdSection.module.scss";
import { ReactComponent as Clock } from "../../../../assets/clock.svg";
import { ReactComponent as CallForward } from "../../../../assets/callforward.svg";
import { ReactComponent as Voicemail } from "../../../../assets/voicemail.svg";
import tablet from "../../../../assets/tablet.png";
const ThirdSection = ({ mobileView }) => {
  return (
    <div className={styles.thirdsection}>
      <div className={styles.thirdsectiontop}>
        <div className={styles.carditem}>
          <div className={styles.card}>
            <div className={styles.cardbox}>
              <Voicemail />
            </div>
            <div className={styles.cardtext}>
              <h3>Operating Hours</h3>
              <p>
                Easily specify what time your business is operational, the rest
                is handled by us.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardbox}>
              <CallForward />
            </div>
            <div className={styles.cardtext}>
              <h3>Call Forwarding</h3>
              <p>
                You can easily forward your business calls outside business
                hours.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardbox}>
              <Clock />
            </div>
            <div className={styles.cardtext}>
              <h3>Voicemail</h3>
              <p>Configure a custom voicemail for your customers.</p>
            </div>
          </div>{" "}
        </div>
        {!mobileView && <img src={tablet} alt="Tablet" />}
      </div>

      <div className={styles.thirdsectionbottom}>
        <h3>Setup your phone system</h3>
        <h4>In just 4 simple steps!</h4>
      </div>
    </div>
  );
};

export default ThirdSection;

import React from "react";
import styles from "./SixthSection.module.scss";
import Accordion from "../../../../Components/Accordion/Accordion";
const SixthSection = ({ mobileView }) => {
  return (
    <div className={styles.sixthsection}>
      <h4>FAQ</h4>

      <div className={styles.accordionList}>
        <Accordion
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
          title="How can Alloblue help my small business?"
        />
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />{" "}
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />{" "}
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />{" "}
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />{" "}
        <Accordion
          title="How can Alloblue help my small business?"
          content="Lorem ipsum dolor sit amet. Qui consequatur eveniet et dolor mollitia aut internos totam ut quia quidem. Sed internos voluptas eum quidem consequuntur et itaque labore in porro commodi."
        />
      </div>
    </div>
  );
};

export default SixthSection;

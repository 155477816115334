import React from "react";
import styles from "./TopSection.module.scss";
import AlloBlueLogo from "../../../../assets/AlloblueLogo.png";
import Salesperson from "../../../../assets/salesperson.png";
import SalespersonElements from "../../../../assets/salespersonElements.png";
import Dialer from "../../../../assets/dialer.png";

import { ReactComponent as Hamburger } from "../../../../assets/hamburger.svg";
import { ReactComponent as WhiteShaded } from "../../../../assets/White O.svg";
import { ReactComponent as GoldenCircle } from "../../../../assets/Ellipse 13.svg";
import { ReactComponent as WhiteDisk } from "../../../../assets/Ellipse 12.svg";
import { ReactComponent as RightArrow } from "../../../../assets/rightArrow.svg";
import { useEffect } from "react";
import { useState } from "react";

const TopSection = ({ mobileView }) => {
  return (
    <div className={styles.topSection}>
      <div className={styles.navbar}>
        <div className={styles.left}>
          <img src={AlloBlueLogo} alt="" />
        </div>

        <div className={styles.right}>
          {!mobileView && (
            <a
              href="https://app.alloblue.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up{" "}
            </a>
          )}
          {mobileView && (
            <a
              href="https://app.alloblue.com/signup"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up{" "}
            </a>
          )}
        </div>
      </div>
      <div className={styles.textsection}>
        <h2>Easy phone system for everyone</h2>
        <p>
          Business phone system made for small businesses. Setup your
          professional phone system in less than 60 seconds
        </p>
        <button>
          Get Started <RightArrow />{" "}
        </button>
      </div>
      <div className={styles.imageElement}>
        <img src={SalespersonElements} alt="" />
      </div>
      {!mobileView && (
        <div className={styles.dialer}>
          <img src={Dialer} alt="" />
        </div>
      )}
      {mobileView && (
        <div className={styles.bgelements}>
          <div className={styles.shaded}>
            <WhiteShaded />
          </div>
          <div className={styles.golden}>
            <GoldenCircle />
          </div>
          <div className={styles.white}>
            <WhiteDisk />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopSection;

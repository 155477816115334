import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Logo from "./assets/logo.svg";
import LoginPage from "./pages/LoginSignUp/LoginPage";
import SignupPage from "./pages/LoginSignUp/SignupPage";
import { AuthProvider } from "./contexts/AuthContext";
import HomePage from "./pages/HomePage";
import OnBoarding from "./pages/OnBoarding/OnBoarding";
import InsuranceLanding from "./pages/InsuranceLanding/InsuranceLanding";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InsuranceLanding />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

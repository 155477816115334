import React from "react";
import styles from "./InsuranceLanding.module.scss";
import TopSection from "./components/TopSection/TopSection";
import SecondSection from "./components/SecondSection/SecondSection";
import ThirdSection from "./components/ThirdSection/ThirdSection";
import ForthSection from "./components/ForthSection/ForthSection";
import FifthSection from "./components/FifthSection/FifthSection";
import SixthSection from "./components/SixthSection/SixthSection";
import BottomSection from "./components/BottomSection/BottomSection";
import { useEffect } from "react";
import { useState } from "react";
import ForthSectionMobile from "./components/ForthSection/ForthSectionMobile";

const InsuranceLanding = () => {
  const [mobileView, setMobileView] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 500) setMobileView(true);
    else setMobileView(false);
  }, [window]);
  return (
    <div className={styles.insuranceLanding}>
      <TopSection mobileView={mobileView} />
      <SecondSection mobileView={mobileView} />
      <ThirdSection mobileView={mobileView} />
      {mobileView ? <ForthSectionMobile /> : <ForthSection />}
      <FifthSection mobileView={mobileView} />
      <SixthSection mobileView={mobileView} />
      <BottomSection mobileView={mobileView} />
    </div>
  );
};

export default InsuranceLanding;

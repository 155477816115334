import React, { useState } from "react";
import styles from "./Review.module.scss";
import { ReactComponent as RightArrow } from "../../../../../assets/arrow-left-thin.svg";
import { ReactComponent as LeftArrow } from "../../../../../assets/arrow-right-thin.svg";
import { ReactComponent as Circle } from "../../../../../assets/circle-small.svg";
import { ReactComponent as CircleFilled } from "../../../../../assets/circle-small-filled.svg";

const reviews = [
  {
    id: 1,
    author: "John Doe",
    position: "Founder & CEO - Acciolbis",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    position: "Founder & CEO - Acciolbis",
    id: 2,
    author: "Jane Smith",
    content:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
  {
    position: "Founder & CEO - Acciolbis",
    id: 3,
    author: "Bob Johnson",
    content:
      "Nulla facilisi. Integer ut efficitur ipsum. Sed tincidunt orci a massa aliquet, a fringilla tortor tempor.",
  },
  {
    position: "Founder & CEO - Acciolbis",
    id: 4,
    author: "Atmadeep Das",
    content:
      "Nulla facilisi. Integer ut efficitur ipsum. Sed tincidunt orci a massa aliquet, a fringilla tortor tempor.",
  },
];

const ReviewSection = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const handlePrevReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={styles.reviewSectionWrapper}>
      <div className={styles.review}>
        <h4>{reviews[currentReviewIndex].author}</h4>
        <h5>{reviews[currentReviewIndex].position}</h5>
        <p>{reviews[currentReviewIndex].content}</p>
      </div>

      <div className={styles.pageindicator}>
        {reviews.map((review, index) => {
          return index === currentReviewIndex ? <CircleFilled /> : <Circle />;
        })}
      </div>

      <div className={styles.reviewControls}>
        <div onClick={handlePrevReview}>
          <LeftArrow />
        </div>
        <div onClick={handleNextReview}>
          {" "}
          <RightArrow />{" "}
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
